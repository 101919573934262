import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SalesRevenueSeat} from "./SalesRevenueSeat";
import {SalesRevenueProduct} from "./SalesRevenueProduct";
import {Status} from "../../../../utils/Status";
import {CurrencyType} from "../../currency/Currency";
import {SalesRevenueCostType} from "./SalesRevenueCostType";
import {ContentElement} from "../../../interface/ContentElement";
import {SalesRevenueField} from "./SalesRevenueField";

export class SalesRevenue extends ApiModel {
    id: number = 0;
    seat_id: number = 0;
    date: string = "";
    billing_month: string = "";
    revenue: number = 0;
    impression: number = 0;
    currency: CurrencyType = CurrencyType.EUR;
    product_id: number = 0;
    bidder_platform_name: string = "";
    cost_amount: number = 0;
    cost_currency: CurrencyType = CurrencyType.EUR;
    cost_type: string = SalesRevenueCostType.DEBOARDING;
    cost_organization_id: number = 0;
    invoice_urls: string[] = [];
    status: string = Status.DRAFT.name;
    seat: SalesRevenueSeat = new SalesRevenueSeat();
    product: SalesRevenueProduct = new SalesRevenueProduct();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) {
            return;
        }
        super.load(o);
        this.loadObject(SalesRevenueField.SEAT, SalesRevenueSeat);
        this.loadObject(SalesRevenueField.PRODUCT, SalesRevenueProduct);
    }

    getJsonParameters(): {} {
        return {
            seat_id: this.seat_id,
            date: this.date,
            billing_month: this.billing_month,
            revenue: this.revenue,
            impression: this.impression,
            currency: this.currency,
            product_id: this.product_id,
            bidder_platform_name: this.bidder_platform_name,
            cost_amount: this.cost_amount,
            cost_currency: this.cost_currency,
            cost_type: this.cost_type,
            cost_organization_id: this.cost_organization_id,
            invoice_urls: this.invoice_urls,
            status: this.status
        };
    }

    static statuses(): Status[] {
        return [
            Status.DRAFT,
            Status.TO_VALIDATE,
            Status.VALIDATED,
            Status.DELETED
        ];
    }

    static currencyTypes(): CurrencyType[] {
        return [
            CurrencyType.EUR,
            CurrencyType.USD,
            CurrencyType.GBP
        ];
    }

    getStatus(): Status {
        switch (this.status) {
            case Status.TO_VALIDATE.name:
                return Status.TO_VALIDATE;
            case Status.VALIDATED.name:
                return Status.VALIDATED;
            case Status.DELETED.name:
                return Status.DELETED;
            default:
                return Status.DRAFT;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, `${this.product.name} - ${this.seat.platform}`);
        element.status = this.getStatus();
        return element;
    }
}
