import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SalesRevenueSearchQuery} from "../model/revenue/sales-revenue/SalesRevenueSearchQuery";
import {SalesRevenueSearchResult} from "../model/revenue/sales-revenue/SalesRevenueSearchResult";
import {SalesRevenue} from "../model/revenue/sales-revenue/SalesRevenue";
import {SalesRevenueFilter} from "../model/revenue/sales-revenue/filter/SalesRevenueFilter";

export class RestSalesRevenue extends Rest implements RestService<SalesRevenue> {
    rootPath = "/console-api/sales-revenue";

    search(params: SalesRevenueSearchQuery): Promise<SalesRevenueSearchResult> {
        return this._client.get(`${this.rootPath}`, SalesRevenueSearchResult, {queryParams: params.getJsonParameters()}) as Promise<SalesRevenueSearchResult>;
    }

    create(revenue: SalesRevenue): Promise<SalesRevenue> {
        return this._client.post(`${this.rootPath}`, revenue, SalesRevenue) as Promise<SalesRevenue>;
    }

    get(revenueId: number): Promise<SalesRevenue> {
        return this._client.get(`${this.rootPath}/${revenueId}`, SalesRevenue) as Promise<SalesRevenue>;
    }

    update(revenue: SalesRevenue): Promise<SalesRevenue> {
        return this._client.put(`${this.rootPath}/${revenue.id}`, revenue, SalesRevenue) as Promise<SalesRevenue>;
    }

    delete(revenueId: number) {
        return this._client.delete(`${this.rootPath}/${revenueId}`);
    }

    validate(revenue: SalesRevenue) {
        return this._client.patch(`${this.rootPath}/${revenue.id}/validate`, revenue, SalesRevenue) as Promise<SalesRevenue>;
    }

    export(): Promise<Blob> {
        return this._client.get(`${this.rootPath}/csv`, undefined) as Promise<Blob>;
    }

    getFilters(): Promise<SalesRevenueFilter> {
        return this._client.get(`${this.rootPath}/filter`, SalesRevenueFilter) as Promise<SalesRevenueFilter>;
    }
}
