import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {SalesRevenueFilterSeat} from "./SalesRevenueFilterSeat";
import {SalesRevenueFilterOrganization} from "./SalesRevenueFilterOrganization";
import {SalesRevenueFilterField} from "./SalesRevenueFilterField";
import {User} from "../../../user/User";
import {SalesRevenueProduct} from "../SalesRevenueProduct";

export class SalesRevenueFilter extends ApiModel {
    seats: SalesRevenueFilterSeat[] = [];
    organizations: SalesRevenueFilterOrganization[] = [];
    owners: User[] = [];
    platforms: string[] = [];
    products: SalesRevenueProduct[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) {
            return;
        }
        super.load(o);
        this.loadObjects(SalesRevenueFilterField.SEATS, SalesRevenueFilterSeat);
        this.loadObjects(SalesRevenueFilterField.ORGANIZATIONS, SalesRevenueFilterOrganization);
        this.loadObjects(SalesRevenueFilterField.OWNERS, User);
        this.loadObjects(SalesRevenueFilterField.PRODUCTS, SalesRevenueProduct);
    }
}
