enum values {
    DATA = "Data",
    DEBOARDING = "Deboarding",
    MEDIA = "Media"
}

export class SalesRevenueCostType {
    static DATA = values.DATA;
    static DEBOARDING = values.DEBOARDING;
    static MEDIA = values.MEDIA;

    static values(): string[] {
        return Object.values(values);
    };
}